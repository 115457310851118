<template>
    <div>
    <div class="table-page-search-wrapper">
  
      <a-form layout="inline" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
        <a-row :gutter="24">
           <a-col :span="6">
              <a-form-item label="场景分类">
                <a-select
                  show-search
                  v-model="pointChangeType"
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请选择"
                  allow-clear
                >
                  <a-select-option :value="i.value" v-for="i of pointChangeTypeArr" :key="i.value">
                    {{ i.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
           <a-col :span="6">
              <a-form-item label="场景二级分类">
                <a-select
                  show-search
                  v-model="pointSecondaryType"
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请选择"
                  allow-clear
                >
                  <a-select-option :value="i.value" v-for="i of pointSecondaryTypeArr" :key="i.value">
                    {{ i.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
             <a-col :span="8">
                  <a-form-item label="积分时间">
                    <a-range-picker @change="onChangeDate" v-model="searchDateArr" format="YYYY-MM-DD" />
                  </a-form-item>
                </a-col>
          <a-col :md="4" :sm="24" style="float: right; text-align: right">
            <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
              <a-button type="primary" style="margin-right: 10px"  @click="initSearchData('search')">查询</a-button>
              <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-wrap">
          <a-table
            :loading="showLoading"
            :columns="columns"
            :data-source="tableData"
            @change="handleChange"
            :pagination="initPagination"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template slot="operation" slot-scope="operation, record">
                <span class="operation" @click="lookDetail(record)">查看明细</span>
              </template>
               <template slot="pointChangeType" slot-scope="pointChangeType">
                 <a-tag color="green" v-if="pointChangeType === 'EARN'">{{initOptionsType(pointChangeType,pointChangeTypeArr)}}</a-tag>
                 <a-tag color="blue" v-if="pointChangeType === 'CONSUME'">{{initOptionsType(pointChangeType,pointChangeTypeArr)}}</a-tag>
                 <a-tag color="red" v-if="pointChangeType === 'EXPIRED'">{{initOptionsType(pointChangeType,pointChangeTypeArr)}}</a-tag>


              </template>
             <template slot="pointSecondaryType" slot-scope="pointSecondaryType">
                <span>{{initOptionsType(pointSecondaryType,pointSecondaryTypeArr)}}</span>
              </template>
            <template slot="whoCreated" slot-scope="whoCreated,record">
                <span v-if="record.whoCreated===null">
                  系统
                </span>
                <span v-else>
                    {{record.whoCreated }}
                </span>
           </template>
          </a-table>
    </div>
  </div>
</template>

<script>
import storage from 'store';
import {
 queryWuKongDouDetail
} from '@/api/headTeacher';
export default {
  name: 'wuKongDouDetail',
  data() {
    return {
    studentCode:'',
    pointChangeType:undefined,
    pointChangeTypeArr:[
      {label:'获取',value:'EARN'},
      {label:'消耗',value:'CONSUME'},
     {label:'过期',value:'EXPIRED'}
    ],
    pointSecondaryTypeArr:[
      {label:'营销场景',value:'marketing'},
      // {label:'学习场景',value:'learning'},
      {label:'人工发放',value:'manual_release'},
     {label:'积分商城兑换',value:'points_mall_redemption'},
    //  {label:'参与活动消耗',value:'activity_consume'},
      {label:'人工扣除',value:'manual_deduction'},
     {label:'过期',value:'expired'},
    ],
    pointSecondaryType:undefined,
   
     tableData:[],
      showLoading:false,
      searchStartTime: '',
      searchEndTime: '',
      searchDateArr: [],
     
     
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '悟空豆分值',
          dataIndex: 'points',
          key: 'points',
       
        },
        {
          title: '场景分类',
          dataIndex: 'pointChangeType',
          key: 'pointChangeType',
          scopedSlots: { customRender: 'pointChangeType' },
        },
         {
          title: '场景二级分类',
          dataIndex: 'pointSecondaryType',
          key: 'pointSecondaryType',
          scopedSlots: { customRender: 'pointSecondaryType' },
        },
         {
              title:'积分原因',
              dataIndex: 'remark',
              key: 'remark',
            },
        {
               title:'积分时间',
              dataIndex: 'whenCreated',
              key: 'whenCreated',
            },
       {
          title: '操作人',
          dataIndex: 'whoCreated',
          key:'whoCreated',
          scopedSlots: { customRender: 'whoCreated' },
        },
      ],
     initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      pageNum: 1,
      current: 0,
      pageSize: 10,
    }
  },
  methods:{

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

 initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },

    onChangeDate(date) {
      if (date.length > 0) {
        this.searchStartTime = date[0].format('YYYY-MM-DD');
        this.searchEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.searchStartTime = null;
        this.searchEndTime = null;
      }
    
    },
  
   initSearchData(act) {

      this.showLoading = true;
      if (act === 'search') {
        this.pageNum = 1;
      }

      const params = {
        studentCode:this.studentCode,
        startTime: this.searchStartTime,
        endTime: this.searchEndTime,
        pointChangeType:this.pointChangeType,
        pointSecondaryType:this.pointSecondaryType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      queryWuKongDouDetail(params)
        .then((res) => {
          const { data } = res;
          this.tableData = data.content;
          this.current = data.currentPage;
          this.pageSize = data.pageSize;
          this.initPagination.total = data.totalCount;
          this.initPagination.current = data.currentPage;
          this.showLoading = false;
        })
        .catch((error) => {
          this.showLoading = false;
        });
    },
   resetCondition() {
      this.searchDateArr = [];
      this.searchStartTime = '';
      this.searchEndTime = '';
      this.pointChangeType = undefined;
      this.pointSecondaryType = undefined;
      this.initSearchData('search')
    },
   handleChange(data, filters, sorter) {
      this.pageNum = data.current;
      this.initPagination.current = data.current;
      this.initPagination.pageSize = data.pageSize;
      this.pageSize = data.pageSize;
      this.initSearchData();
    },
  },
   created() {
   
    this.studentCode=this.$route.query.studentCode

 this.initSearchData()
 
  
  },
}
</script>

<style lang="less" scoped>
.table-wrap {
  padding: 20px;
  background: #fff;
}
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 105px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
  .operation {
    color: #43d186;
    cursor: pointer;
  }
  .explainTC {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('~@/assets/explain.png') no-repeat center;
  background-size: 100%;
  margin-left:8px;
  cursor: pointer;
}
.title-style{
  display: flex;
  align-items: center;
}
</style>